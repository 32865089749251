import styled from "styled-components";

export const Container = styled.div`
  height: 1000px;
`;

export const LinkButtonContainer = styled.div`
  text-align: center;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TagContainer = styled.div`
  & > * {
    opacity: 1 !important;
  }
`;
